<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form">
        <form @submit.prevent="submit">
          <!-- form -->
          <div class="row">
            <div class="col-12">
              <base-input label="Unidade de Medida" placeholder="Ex: KG, ML" v-model="form.unity"></base-input>
            </div>

            <div class="col-12">
              <label class="mr-3 mb-0">Status:</label>
              <el-radio-group v-model="form.status">
                <el-radio class="mb-0" :label="1">Ativo</el-radio>
                <el-radio class="mb-0" :label="0">Inativo</el-radio>
              </el-radio-group>
            </div>

            <!-- botoes -->
            <div class="col-12 mt-3">
              <base-button @click.prevent.stop="goBack()" type="danger">Cancelar</base-button>
              <base-button native-type="submit" type="success" class="ml-3">Salvar</base-button>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import swal from 'sweetalert2'
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
export default {
  data: () => ({
    form: {
      status: 0
    },
    types: []
  }),
  methods: {
    async getData () {
      const id = this.$route.params.id || false
      if (id) {
        this.form = (await this.$http.get(`unities/${id}`)).data.data[0]
      }
    },
    async submit () {
      if (this.validate()) {
        const id = this.$route.params.id || false
        let status
        try {
          if (id) {
            status = (await this.$http.put(`unities/${id}`, this.form)).data.data.status
          }
          if (!id) status = (await this.$http.post('unities', this.form)).data.data.status
        } catch (e) {
          status = e.response.status
        }
        if (status === 200) {
          swal.fire({
            title: 'Salvo!',
            text: `"${this.form.unity}" salvo com sucesso.`,
            showConfirmButton: false,
            timer: 1000
          })
          this.goBack()
        } else {
          swal({
            title: 'Erro!',
            text: 'Ocorreu um erro, tente novamente mais tarde.',
            denyButtonText: 'Ok',
            showDenyButton: true,
            showConfirmButton: false
          })
        }
      } else {
        swal({
          title: 'Erro!',
          text: 'Por favor, preencha todos os campos obrigatórios.',
          denyButtonText: 'Ok',
          showDenyButton: true,
          showConfirmButton: false
        })
      }
    },
    goBack () {
      window.history.go(-1)
    },
    validate () {
      const form = this.form
      if (!form.unity || !form.unity.trim()) return false
      return true
    },
    selectedValue (newValue) {
      this.form.status = newValue
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
